
import { parseDateTime } from '@/shared/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, VModel, Watch } from 'vue-property-decorator';
import { ConsentVersion } from '@/modules/consents/shared/models/consent-version';
import { consentsModule } from '../../shared/state/module';
import { ConsentsState } from '../../shared/state/state';
import ConsentStaticCard from './static-card.vue';
import Item from '@/modules/tickets/components/list/item.vue';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import { AxiosResponse } from 'axios';
import { MetaItem } from '@/shared/types';

@Component({
    components: {
        ConsentStaticCard,
        InfiniteScroll,
    },
})
export default class ConsentEditHistory extends Vue {
    @Prop(Number) public consentId!: number;
    public show = false;
    public actionsTypes = consentsModule.actionsTypes;
    public parseDateTime = parseDateTime;
    public store: ConsentsState = this.$store.state.consentsState;

    get currentUserId() {
        return this.$store.state.authState.user.id;
    }

    get fetchUrl() {
        return `/api/v1/consents/${this.consentId}/versions`;
    }

    get items() {
        return this.store.constentVersions;
    }

    set items(val) {
        this.store.constentVersions = val;
    }

    get meta() {
        return this.store.versionsMeta as MetaItem;
    }

    set meta(val: MetaItem) {
        this.$set(this.store, 'versionsMeta', val);
    }

    get selectedItem() {
        if (this.store.currentlyViewedVersion) {
            return this.store.currentlyViewedVersion.version;
        } else if (this.items.length > 0) {
            return this.items[0].version;
        } else {
            return 1;
        }
    }

    set selectedItem(val) {
        this.store.currentlyViewedVersion = this.items.find((el) => el.version === val) as ConsentVersion;
    }

    public onVersionHover(item: ConsentVersion | null) {
        if (this.$vuetify.breakpoint.smAndDown) {
            return;
        }
        this.$emit('previewItemChange', item);
    }

    public getListItemAttrs(index: number, item: ConsentVersion) {
        if (index === 0) {
            return { [`${item.symbol ? 'three-line' : 'two-line'}`]: true };
        } else {
            return { [`${item.symbol ? 'two-line' : 'single-line'}`]: true };
        }
    }

    public onExtraFetchSuccess(res: AxiosResponse) {
        this.items.push(...res.data.data);
    }

    // @Watch('selectedItem', { immediate: true })
    // public onSelectedItemChange(val: number, oldVal: number | undefined) {
    //     this.store.currentlyViewedVersion = this.items.find(
    //         (el) => el.version === val,
    //     ) as ConsentVersion;
    //     if (oldVal) this.$emit('selection');
    // }
}
