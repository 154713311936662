
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component
export default class ConsentStaticCard extends Vue {
    @Prop(String) public htmlContent!: string;
    @Prop(String) public positiveBtn!: string;
    @Prop(String) public negativeBtn!: string;
    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';

}
