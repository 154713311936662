
import DetailsComponent from '@/shared/components/layout/details/details-component';
import Component from 'vue-class-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import { ConsentProcess } from '../../shared/models/consent-process';
import { consentProcessesModule } from '../../shared/state/submodules';
import ProcessItemsList from './items/process-items-list.vue';
import { Prop } from 'vue-property-decorator';
import ConsentDetails from '../details/details.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { ConsentProcessItem } from '@/modules/consents/shared/models/consent_process-item';
import ConsentProcessDataRequest from '@/modules/consents/shared/requests/consent-process-data-request';
import _ from 'lodash';
import { Consent } from '@/modules/consents/shared';
import { ConsentProcessesState } from '@/modules/consents/shared/state/states/consent-processes';
import ProcessItemTopBar from './items/process-item-top-bar.vue';
import { ConsentsState } from '../../shared/state/state';
import ConsentProcessForm from './form.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

type consentProcessDateKey = 'active_from' | 'active_to';

@Component({
    components: {
        TopToolbar,
        ProcessItemsList,
        ConsentDetails,
        DatePicker,
        ProcessItemTopBar,
        ConsentProcessForm,
        TooltipBtn
    },
})
export default class ConsentProcessDetails extends DetailsComponent<ConsentProcess> {
    @Prop() public declare id: string;
    public store: ConsentProcessesState = this.$store.state.consentsState.processesState;
    public consentsState: ConsentsState = this.$store.state.consentsState;
    public actionsTypes = consentProcessesModule.actionsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public formDialog = false;
    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';
    public consentMobilePreview = false;

    get editedItem() {
        return this.store.editedItem;
    }
    set editedItem(val: ConsentProcessDataRequest | undefined) {
        this.store.editedItem = val;
    }

    get currentItem() {
        return this.store.currentItem;
    }
    set currentItem(val: ConsentProcessItem | null) {
        this.store.currentItem = val;
        if (val) {
            this.consentsState.current = val.consent;
        } else {
            this.consentsState.current = val;
        }
    }

    get loadingItems() {
        return this.store.loadingItems;
    }

    get currentConsent() {
        return this.consentsState.current ?? null;
    }
    set currentConsent(val: Consent | null) {
        this.consentsState.current = val;
    }

    public created() {
        this.store.items = [];
        this.store.currentItem = null;
        this.currentConsent = null;
        this.fetchDetails();
    }

    public showForm() {
        this.editedItem = _.cloneDeep(this.store.current) as ConsentProcessDataRequest;
        this.formDialog = true;
    }

    public onItemUpdated(item: ConsentProcessItem) {
        this.currentItem = item;
    }

    public onSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`consents.processes.${action}`);
    }
}
