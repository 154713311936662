
import { Component } from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';

import ConsentProcessDetails from './details.vue';
import ConsentProcessForm from './form.vue';
import { ConsentProcess } from '../../shared/models/consent-process';
import ConsentProcessDataRequest from '../../shared/requests/consent-process-data-request';
import { consentProcessesModule } from '../../shared/state/submodules';
import { consentProcessListHeaders } from '../../shared/config';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        ItemsTable,
        ConsentProcessDetails,
        ConsentProcessForm,
        TooltipBtn,
    },
})
export default class ConsentProcessList extends ListComponent<ConsentProcess, ConsentProcessDataRequest> {
    public headers: object[] = consentProcessListHeaders;
    public actionsTypes = consentProcessesModule.actionsTypes;
    public mutationTypes = consentProcessesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.consentsState.processesState;
    public props: ModuleProps = consentProcessesModule.moduleProps;

    public mounted() {
        this.fetchData();
    }

    public onItemSaved({ data }: any) {
        this.itemSaved();
        this.goToItem(
            'consent-process-details',
            {
                id: String(data.id),
            },
            data,
        );
    }
}
