
/**
 *  Dependencies list:
 *  - shared
 *  - module
 */
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';

import { consentsModule, ConsentDataRequest } from '../shared';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        ActionConfirmDialog,
        Wysiwyg,
    },
    props: {
        itemData: Object,
        edit: {
            type: Boolean,
            default: false,
        },
        show: Boolean,
        next: {
            type: Boolean,
            default: true,
        },
    },
})
export default class ConsentForm extends FormComponent<ConsentDataRequest> {
    public actionsTypes = consentsModule.actionsTypes;
    public store: any = this.$store.state.consentsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new ConsentDataRequest();

    get formTitle() {
        return this.edit ? 'Edytuj Zgodę' : 'Dodaj Zgodę';
    }

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.createConsentForm;
    }

    public async submitForm(e: any, next?: boolean) {
        this.prepareData();
        return callSuper(this, 'submitForm', e, next);
    }

    private prepareData() {
        if (
            this.itemData.answer_positive !== undefined &&
            this.itemData.answer_positive.length === 0
        ) {
            this.$delete(this.itemData, 'answer_positive');
        }
        if (
            this.itemData.answer_negative !== undefined &&
            this.itemData.answer_negative.length === 0
        ) {
            this.$delete(this.itemData, 'answer_negative');
        }
        if (!this.itemData.hasOwnProperty('is_required')) {
            this.$set(this.itemData, 'is_required', false);
        }
    }
}
