import { ConsentProcessItem } from '@/modules/consents/shared/models/consent_process-item';
import { parseDateTime } from '@/shared/helpers';
import moment from 'moment';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

type itemType = 'unactive' | 'indefinite' | 'active' | 'expired';

@Component
export default class ProcessItemComponent extends Vue {
    @Prop(Object) public item!: ConsentProcessItem;
    public moment = moment;
    public parseDateTime = parseDateTime;

    get text() {
        switch (this.type) {
            case 'unactive':
                if (this.item.active_from) {
                    return `Aktywacja od ${parseDateTime(
                        this.item.active_from,
                        'DD-MM-YYYY',
                    )}`;
                }
                return 'Brak zaplanowanej aktywacji';
            case 'expired':
                return `Wygasła od ${parseDateTime(
                    this.item.active_to,
                    'DD-MM-YYYY',
                )}`;
            case 'indefinite':
                return 'Ważna do odwołania';
            default:
                return `Ważna do ${parseDateTime(
                    this.item.active_to,
                    'DD-MM-YYYY',
                )}`;
        }
    }

    get statusName() {
        switch (this.type) {
            case 'unactive':
                return 'Nieakywna';
            case 'expired':
                return 'Wygasła';
            default:
                return 'Aktywna';
        }
    }

    get color() {
        switch (this.type) {
            case 'unactive':
                return 'grey';
            case 'expired':
                return 'red';
            default:
                return 'primary';
        }
    }

    get type(): itemType {
        const item = this.item;
        if (!item.active_from || item.active_from && moment(item.active_from).isSameOrAfter()) {
            return 'unactive';
        }

        if (!item.active_to) {
            return 'indefinite';
        }

        if (item.active_to && moment(item.active_to).isAfter()) {
            return 'active';
        } else {
            return 'expired';
        }
    }
}
