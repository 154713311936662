
import { ConsentProcessItem } from '@/modules/consents/shared/models/consent_process-item';
import ConsentProcessItemDataRequest from '@/modules/consents/shared/requests/consent-proces-item-data-request';
import { ConsentsState } from '@/modules/consents/shared/state/state';
import { ConsentProcessesState } from '@/modules/consents/shared/state/states/consent-processes';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { objectToQueryString, parseDate } from '@/shared/helpers';
import { httpClient } from '@/shared/services';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import ProcessItemComponent from './process-item-component';

type consentProcessDateKey = 'active_from' | 'active_to';

@Component({
    components: {
        DatePicker,
        TooltipBtn,
    },
})
export default class ProcessItemTopBar extends ProcessItemComponent {
    @Prop(Function) public isPermitted!: (arg: string) => boolean;
    public store: ConsentProcessesState = this.$store.state.consentsState.processesState;
    public consentsState: ConsentsState = this.$store.state.consentsState;
    public showItemForm = false;
    public editedItem = {} as ConsentProcessItem;
    public isEditedItemIndefinite = false;
    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';
    public moment = moment;
    public parseDate = parseDate;

    get isAnyDateChanged() {
        if (!this.item || !this.editedItem) {
            return false;
        }
        return (
            !moment(this.editedItem.active_from).isSame(this.item.active_from, 'day') ||
            !moment(this.editedItem.active_to).isSame(this.item.active_to, 'day')
        );
    }

    get activeFromMin() {
        return moment().toISOString();
    }

    get activeToMin() {
        const activeFrom = moment(this.editedItem.active_from);

        if (moment().isBefore(activeFrom)) {
            return moment(this.editedItem.active_from).add(1, 'd').toISOString();
        } else {
            return moment().add(1, 'd').toISOString();
        }
    }

    public onEditedItemDateChange(key: consentProcessDateKey, value: any) {
        this.$set(this.editedItem, key, value ? value : '');
    }

    public cancel() {
        this.showItemForm = false;
        if (this.item) {
            this.editedItem = _.cloneDeep(this.item);
        } else {
            this.editedItem = {} as ConsentProcessItem;
        }
    }

    public onSave() {
        this.updateItem(this.parseParams());
    }

    public updateItem = (params: ConsentProcessItemDataRequest) => {
        const url = `/api/v1/consents/processItems/${this.item.id}?${objectToQueryString(params)}`;

        httpClient
            .put(url)
            .then((res: AxiosResponse) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Zaktualizowano termin zgody',
                });

                this.store.lastUpdatedItem = res.data.data;
                this.$emit('itemUpdated', res.data.data);
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            });
    }

    private parseParams(item = this.editedItem) {
        const { consent, ...rest } = item;
        return {
            ...rest,
            consent_id: consent.id,
        } as ConsentProcessItemDataRequest;
    }

    @Watch('item')
    private onItemChange(val?: ConsentProcessItem) {
        if (this.showItemForm) {
            this.cancel();
        }

        if (!val) {
            return;
        }

        this.editedItem = _.cloneDeep(val);
    }

    @Watch('isEditedItemIndefinite')
    private onIndefiniteSwitch(val: boolean) {
        if (val) {
            this.$set(this.editedItem, 'active_to', '');
        } else {
            this.$set(this.editedItem, 'active_to', this.item ? this.item.active_to : moment().add(1, 'd'));
        }
    }
}
