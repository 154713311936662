
import { Component } from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';

import ConsentDetails from './details/details.vue';
import ConsentForm from './form.vue';
import { consentsModule, consentListHeaders, Consent, ConsentDataRequest } from '../shared/index';
import { ConsentVersion } from '../shared/models/consent-version';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        ItemsTable,
        ConsentDetails,
        ConsentForm,
        TooltipBtn,
    },
})
export default class ConsentList extends ListComponent<Consent, ConsentDataRequest> {
    public headers: object[] = consentListHeaders;
    public actionsTypes = consentsModule.actionsTypes;
    public mutationTypes = consentsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.consentsState;
    public props: ModuleProps = consentsModule.moduleProps;

    public mounted() {
        this.fetchData();
    }

    public onShowForm(item: ConsentVersion) {
        this.editedItem = item;
        this.formDialog = true;
    }
}
