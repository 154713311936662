
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ProcessItemComponent from './process-item-component';

@Component
export default class ProccessItemListItem extends ProcessItemComponent {
    @Prop(Boolean) public isDeletePermitted!: boolean;
    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';

}
