var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('items-table',{attrs:{"headers":_vm.headers,"props":_vm.props,"form-width":"600px","mutation-types":_vm.mutationTypes,"fetch-action":_vm.fetchAction,"remove-action":_vm.removeAction},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('consent-process-form',{key:_vm.formDialog,attrs:{"item-data":_vm.editedItem,"edit":_vm.edit,"show":_vm.formDialog,"next":false},on:{"saved":_vm.onItemSaved,"canceled":function($event){return _vm.closeForm()},"next":function($event){_vm.formDialog = true}}})]},proxy:true},{key:"name",fn:function({ item }){return [_c('a',{on:{"click":function($event){_vm.goToItem(
                        'consent-process-details',
                        {
                            id: String(item.id),
                        },
                        item,
                    )}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.updated_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.parseDateTime(item.created_at, 'DD MMMM YYYY HH:mm'))+" ")]}},{key:"actions",fn:function({ item }){return [_c('tooltip-btn',{directives:[{name:"id",rawName:"v-id",value:(_vm.isPermitted('delete')),expression:"isPermitted('delete')"}],attrs:{"tooltipText":_vm.isPermitted('delete') ? 'usuń' : 'Brak uprawnień',"disabled":!_vm.isPermitted('delete'),"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"pa-2",attrs:{"small":""}},[_vm._v("mdi-delete ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }