
import { Component, Prop } from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { consentsModule, Consent } from '../../shared';
import ConsentStaticCard from './static-card.vue';
import ConsentEditHistory from './consent-edit-history.vue';
import { ConsentsState } from '../../shared/state/state';
import { ConsentVersion } from '../../shared/models/consent-version';
import ConsentForm from '../form.vue';
import _ from 'lodash';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        ConsentStaticCard,
        ConsentEditHistory,
        ConsentForm,
        TooltipBtn,
    },
})
export default class ConsentDetails extends DetailsComponent<Consent> {
    @Prop({ type: Boolean, default: false }) public forProcess!: boolean;
    public store: ConsentsState = this.$store.state.consentsState;
    public actionsTypes = consentsModule.actionsTypes;
    public fetchVersionsAction: string = this.actionsTypes.FETCH_VERSIONS;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public showHistory = false;
    public versionsLoading = false;
    public formDialog = false;
    public editedItem = {};
    public showPreviewDialog = false;
    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';

    // Changes only preview item, used on history item hover
    public previewItem: ConsentVersion | null = null;

    // Changes current currentlyViewedVersion (allows to copy data edit form)
    get currentlyViewedVersion(): ConsentVersion {
        if (this.store.currentlyViewedVersion) {
            return this.store.currentlyViewedVersion;
        } else {
            return this.item as ConsentVersion;
        }
    }
    set currentlyViewedVersion(val) {
        this.$set(this.store, 'currentlyViewedVersion', val);
    }

    get activeVersionNumber() {
        return this.store.activeVersionNumber;
    }
    set activeVersionNumber(val) {
        this.$set(this.store, 'activeVersionNumber', val);
    }

    get isPreviewItemActive() {
        return this.previewItem && this.previewItem.version === this.activeVersionNumber;
    }

    get editBtnTooltipText() {
        if (this.versionsLoading) {
            return 'Ładowanie';
        } else if (this.currentlyViewedVersion.version === this.activeVersionNumber) {
            return 'Stwórz nową wersje';
        } else {
            return 'Przywróć wersje';
        }
    }

    get previewLabel() {
        if (!this.previewItem) {
            return '';
        }
        const userName =
            this.previewItem.user.id === this.currentUserId
                ? '(Ty) ' + this.previewItem.user.name
                : this.previewItem.user.name;
        return this.versionsLoading || this.isPreviewItemActive
            ? 'Aktualny'
            : `${userName} - ${this.parseDateTime(this.previewItem.created_at, 'DD MMMM YYYY HH:mm')}`;
    }

    get currentUserId() {
        return this.$store.state.authState.user.id;
    }

    public created() {
        this.$watch(
            'item',
            (val: Consent) => {
                if (val) {
                    this.fetchVersions();
                }
            },
            { immediate: true },
        );
    }

    public itemSaved() {
        this.fetchVersions();
        this.formDialog = false;
    }

    public changePreview(item: ConsentVersion) {
        if (item) {
            this.previewItem = item;
        } else {
            this.previewItem = this.currentlyViewedVersion;
        }
    }

    public showForm(item: ConsentVersion) {
        this.editedItem = {
            ..._.cloneDeep(item),
            id: this.item.id,
        };
        this.formDialog = true;
    }

    public fetchVersions() {
        this.versionsLoading = true;
        this.$store
            .dispatch(this.fetchVersionsAction, this.item.id)
            .then((data: any) => {
                this.activeVersionNumber = data.data[0].version;
                this.currentlyViewedVersion = data.data[0];
                this.previewItem = this.currentlyViewedVersion;
            })
            .finally(() => {
                this.versionsLoading = false;
            });
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`consents.${action}`);
    }
}
