
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import ConsentProcessDataRequest from '../../shared/requests/consent-process-data-request';
import { consentProcessesModule } from '../../shared/state/submodules';
import { ConsentProcessesState } from '../../shared/state/states/consent-processes';

@Component({
    components: { FormActions },
    props: {
        itemData: Object,
        edit: {
            type: Boolean,
            default: false,
        },
        show: Boolean,
        next: {
            type: Boolean,
            default: true,
        },
    },
})
export default class ConsentProcessForm extends FormComponent<ConsentProcessDataRequest> {
    public actionsTypes = consentProcessesModule.actionsTypes;
    public store: ConsentProcessesState =
        this.$store.state.consentsState.consentProcessesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public mutationTypes = consentProcessesModule.mutationsTypes;
    public requestData = new ConsentProcessDataRequest();

    public created() {
        //
    }

    public isUrlSafe = (v: string) => {
        return v && /^[a-zA-Z0-9_-]*$/.test(v) || 'Zły slug';
    }

    get formTitle(): string {
        return this.edit
            ? 'Edytuj proces'
            : 'Dodaj proces';
    }

    public initForm() {
        this.form = this.$refs.createConsentProcessForm;
    }
}
