import { render, staticRenderFns } from "./consent-edit-history.vue?vue&type=template&id=67f55e6a&scoped=true&"
import script from "./consent-edit-history.vue?vue&type=script&lang=ts&"
export * from "./consent-edit-history.vue?vue&type=script&lang=ts&"
import style0 from "./consent-edit-history.vue?vue&type=style&index=0&id=67f55e6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f55e6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VTooltip})
