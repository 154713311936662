
import { Consent, ConsentDataRequest, consentsModule } from '@/modules/consents/shared';
import { ConsentProcessItem } from '@/modules/consents/shared/models/consent_process-item';
import ConsentProcessItemDataRequest from '@/modules/consents/shared/requests/consent-proces-item-data-request';
import { ConsentsState } from '@/modules/consents/shared/state/state';
import { ConsentProcessesState } from '@/modules/consents/shared/state/states/consent-processes';
import { consentProcessesModule } from '@/modules/consents/shared/state/submodules';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import { objectToQueryString, parseDateTime } from '@/shared/helpers';
import { httpClient } from '@/shared/services';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import ConsentForm from '../../form.vue';
import ProccessItemListItem from './proces-items-list-item.vue';
@Component({
    components: {
        ListToolbar,
        ConsentForm,
        ProccessItemListItem,
        InfiniteScroll,
        TooltipBtn,
    },
})
export default class ProcessItemsList extends Vue {
    @Prop(Function) public isPermitted!: (arg: string) => boolean;
    @Prop(Number) public processId!: number;
    @Prop(Boolean) public processLoading!: boolean;
    public consentsState: ConsentsState = this.$store.state.consentsState;
    public store: ConsentProcessesState = this.consentsState.processesState;
    public actionTypes = consentProcessesModule.actionsTypes;
    public consentsActionTypes = consentsModule.actionsTypes;
    public processItems = [];
    public parseDateTime = parseDateTime;
    public formDialog = false;
    public selectedItem = 0;
    public search = '';
    public searchTimer: number = 0;
    public searchedConsent: Consent | null = null;
    public consentsFetchUrl = 'api/v1/consents';
    public FetchUrl = 'api/v1/consents';
    public isMApp = this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';

    get consents() {
        return this.consentsState.data;
    }

    get loadingItems() {
        return this.store.loadingItems;
    }

    get items() {
        return this.store.items;
    }
    set items(val: ConsentProcessItem[]) {
        this.$set(this.store, 'items', val);
    }

    get lastUpdatedItem() {
        return this.store.lastUpdatedItem;
    }

    get editedItem() {
        return this.consentsState.editedItem;
    }
    set editedItem(val: ConsentDataRequest | undefined) {
        this.$set(this.consentsState, 'editedItem', val);
    }

    get url() {
        return `api/v1/consents/processes/${this.processId}/processItems`;
    }

    get consentsFetchParams() {
        return {
            filters: {
                search: this.search,
            },
            is_extra_fetch: true,
        };
    }

    get meta() {
        return this.store.meta;
    }
    set meta(val: any) {
        this.store.meta = val;
    }

    get consentsMeta() {
        return this.consentsState.meta;
    }
    set consentsMeta(val: any) {
        this.consentsState.meta = val;
    }

    public created() {
        this.$watch(
            'processLoading',
            (val) => {
                if (!val) {
                    this.fetchData(() => {
                        if (this.items.length > 0) {
                            this.setCurrentItem(this.items[0]);
                        }
                    });
                }
            },
            { immediate: true },
        );
    }

    public createItem() {
        this.editedItem = {};
        this.formDialog = true;
    }

    public itemSaved({ data }: { data: Consent }) {
        this.$emit('consentAdded');
        this.formDialog = false;

        this.addItem(data.id);
    }

    public addItem(
        consentId: number,
        cb = (arg0: AxiosResponse) => {
            /**/
        },
    ) {
        const url = `/api/v1/consents/processes/${this.processId}/processItems?${objectToQueryString({
            consent_id: consentId,
        })}`;

        httpClient
            .post(url)
            .then((res: AxiosResponse) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Dodano zgodę do procesu',
                });

                this.items.unshift(res.data.data);
                this.$nextTick(() => {
                    this.setCurrentItem(res.data.data);
                    this.$set(this, 'formDialog', false);
                    this.$forceUpdate();
                });
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            });
    }

    public deleteItem(itemId: number) {
        const url = `/api/v1/consents/processItems/${itemId}`;

        httpClient
            .delete(url)
            .then((res: AxiosResponse) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Usunięto zgodę z procesu',
                });

                this.items = this.items.filter((item) => item.id !== itemId);
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            });
    }

    public fetchData(
        cb = (arg0: AxiosResponse) => {
            /**/
        },
    ) {
        this.$store.dispatch(this.actionTypes.FETCH_ITEMS, this.processId).then((res: AxiosResponse) => cb(res));
    }

    public setCurrentItem(val: ConsentProcessItem) {
        this.$set(this.store, 'currentItem', val);
        this.$set(this.consentsState, 'current', val.consent);
        this.selectedItem = val.id;
    }

    public fetchConsents() {
        this.$store.dispatch(this.consentsActionTypes.FETCH_DATA, this.consentsFetchParams);
    }

    public consentUsed({ id }: Consent) {
        return this.items.map((el) => el.consent_id).includes(id);
    }

    public onExtraFetchSuccess({ data }: AxiosResponse) {
        this.items.push(...data.data.filter((el: ConsentProcessItem) => !this.consentUsed(el.consent)));
    }

    public onConsentsExtraFetchSuccess({ data }: AxiosResponse) {
        this.consents.push(...data.data);
    }

    @Watch('selectedItem')
    public onSelectionChange(val: number) {
        if (this.store.currentItem && this.store.currentItem.id === val) {
            return;
        } else {
            const newCurrent = this.items.find((el) => el.id === val);
            if (!newCurrent) {
                return;
            }
            this.setCurrentItem(newCurrent);
        }
    }

    @Watch('search')
    public onSearch() {
        clearTimeout(this.searchTimer);
        this.searchTimer = window.setTimeout(() => {
            this.fetchConsents();
        }, 200);
    }

    @Watch('lastUpdatedItem')
    public onItemUpdate(item?: ConsentProcessItem) {
        if (!item) {
            return;
        }
        const { id } = item;
        const index = this.items.findIndex((el) => el.id === id);
        if (index !== -1) {
            this.$set(this.items, index, item);
        }
    }
}
